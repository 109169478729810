var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-upload"
  }, [_c("el-steps", {
    attrs: {
      active: _vm.active,
      "align-center": ""
    }
  }, _vm._l(_vm.stepData, function (item) {
    return _c("el-step", {
      key: item.index,
      attrs: {
        title: item.title
      }
    });
  }), 1), _vm.active === 1 ? _c("div", {
    staticClass: "upload-step"
  }, [_c("el-date-picker", {
    staticClass: "month-picker",
    attrs: {
      format: "yyyy 年 MM 月",
      "value-format": "yyyyMM",
      type: "month",
      editable: false,
      clearable: false,
      placeholder: "请选择月份",
      "picker-options": _vm.pickerOptions
    },
    model: {
      value: _vm.yearMonth,
      callback: function ($$v) {
        _vm.yearMonth = $$v;
      },
      expression: "yearMonth"
    }
  }), _c("UploadBtn", {
    attrs: {
      "year-month": _vm.yearMonth
    },
    on: {
      onSuccess: _vm.onSuccess
    }
  }), _c("div", {
    staticClass: "downloadTem"
  }, [[_vm._v("建议使用本工资表模版，Excel中请务必包含")], _vm._l(_vm.templateData, function (item, index) {
    return [[_vm._v(" 【"), _c("span", {
      key: item
    }, [_vm._v(_vm._s(item))]), _vm._v("】 ")], index < _vm.templateData.length - 1 ? [_vm._v("、")] : _vm._e()];
  }), [_vm._v("数据项；如需参考模版")], _c("el-button", {
    staticClass: "edit",
    attrs: {
      type: "text",
      size: "small"
    },
    on: {
      click: _vm.onDownloadTemplate
    }
  }, [_vm._v("下载 ")])], 2)], 1) : _vm._e(), _vm.active === 2 ? _c("div", {
    staticClass: "check-payslip-container"
  }, [_c("CheckPayslip"), _c("div", {
    ref: "tabFooter",
    staticClass: "footer-container flex-container"
  }, [_c("section", {
    staticClass: "error-container"
  }, [_vm.assocErrorData.length > 0 ? _c("div", {
    staticClass: "error-msg"
  }, [_c("span", [_vm._v("关联员工失败（" + _vm._s(_vm.assocErrorData.length) + "条）无法发放工资条")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.showAssocErrorDialogFn
    }
  }, [_vm._v("查看详情 ")])], 1) : _vm._e(), _vm.errorMsgData.length > 0 ? _c("div", {
    staticClass: "error-msg"
  }, [_c("span", [_vm._v("警告：字段为空（" + _vm._s(_vm.errorMsgData.length) + "条）")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.showErrorDialogFn
    }
  }, [_vm._v("查看详情 ")])], 1) : _vm._e(), _vm.repeatPhonesData.length > 0 ? _c("div", {
    staticClass: "error-msg"
  }, [_c("span", [_vm._v("警告：手机号重复（" + _vm._s(_vm.repeatPhonesData.length) + "条）")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.showRepeatDialogFn
    }
  }, [_vm._v("查看详情 ")])], 1) : _vm._e()]), _c("div", [_c("el-tooltip", {
    staticClass: "tooltip-item",
    attrs: {
      effect: "dark",
      content: "返回首页，请删除错误数据后重新上传",
      placement: "bottom"
    }
  }, [_c("el-button", {
    on: {
      click: _vm.goManagerPageFn
    }
  }, [_vm._v("重新上传")])], 1), !_vm.hasCheck ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.checkEmployeesFn
    }
  }, [_vm._v("核对关联员工 ")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleNextStep
    }
  }, [_vm._v("下一步 ")])], 1)]), _vm.assocVisible ? _c("AssocInfo", {
    attrs: {
      "assoc-visible": _vm.assocVisible,
      "assoc-error-data": _vm.assocErrorData
    },
    on: {
      "update:assocVisible": function ($event) {
        _vm.assocVisible = $event;
      },
      "update:assoc-visible": function ($event) {
        _vm.assocVisible = $event;
      }
    }
  }) : _vm._e(), _vm.errorVisible ? _c("ErrorInfo", {
    attrs: {
      "error-visible": _vm.errorVisible,
      "error-msg-data": _vm.errorMsgData
    },
    on: {
      "update:errorVisible": function ($event) {
        _vm.errorVisible = $event;
      },
      "update:error-visible": function ($event) {
        _vm.errorVisible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "custom-title"
    },
    slot: "custom-title"
  }, [_vm._v(" 导入数据中以下字段为空： ")])]) : _vm._e(), _vm.repeatVisible ? _c("ErrorInfo", {
    attrs: {
      "error-visible": _vm.repeatVisible,
      "error-msg-data": _vm.repeatPhonesData
    },
    on: {
      "update:errorVisible": function ($event) {
        _vm.repeatVisible = $event;
      },
      "update:error-visible": function ($event) {
        _vm.repeatVisible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "custom-title"
    },
    slot: "custom-title"
  }, [_vm._v("以下手机号重复：")])]) : _vm._e()], 1) : _vm._e(), _vm.active === 3 ? [_c("SendPayslip", {
    ref: "sendChildRef",
    attrs: {
      "year-month": _vm.yearMonth
    }
  }), _c("section", {
    staticClass: "footer-container"
  }, [_c("div", [_c("el-button", {
    on: {
      click: _vm.handlePrev
    }
  }, [_vm._v("上一步")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSendFn
    }
  }, [_vm._v("保存并发送")])], 1)])] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };