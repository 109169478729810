var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      title: "提示",
      visible: _vm.assocVisible,
      width: "400px",
      center: "",
      "before-close": _vm.handleCloseFn,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.assocVisible = $event;
      }
    }
  }, [_c("header", {
    staticClass: "header"
  }, [_vm._v("以下手机号无法在企业微信匹配到对应员工")]), _c("ul", {
    staticClass: "assoc-con"
  }, [_c("li", [_c("span", [_vm._v("姓名")]), _c("span", [_vm._v("手机号")])]), _vm._l(_vm.assocErrorData, function (item) {
    return _c("li", {
      key: item.mobile
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("span", [_vm._v(_vm._s(item.mobile))])]);
  })], 2), _c("p", {
    staticClass: "tip"
  }, [_vm._v("注：以上人员不会收到工资条推送消息")]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCloseFn
    }
  }, [_vm._v("确 定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };