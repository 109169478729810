import ColumnItem from './ColumnItem';
export default {
  props: {
    // 表格的数据
    tableData: {
      type: Array,
      required: true
    },
    // 多级表头的数据
    tableHeader: {
      type: Array,
      required: true
    },
    pages: {
      type: Object,
      required: true
    }
  },
  components: {
    ColumnItem
  },
  methods: {
    indexMethod(index) {
      const {
        currentPage,
        pageSize
      } = this.pages;
      return index + 1 + (currentPage - 1) * pageSize;
    }
  }
};