var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "send-payslip"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基础设置")]), _c("el-form", {
    ref: "formValidate",
    staticClass: "send-form",
    attrs: {
      model: _vm.formValidate,
      rules: _vm.ruleValidate,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "工资标题：",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入工资标题"
    },
    model: {
      value: _vm.formValidate.title,
      callback: function ($$v) {
        _vm.$set(_vm.formValidate, "title", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formValidate.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "通知公告：",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "非必填",
      rows: 3,
      resize: "none"
    },
    model: {
      value: _vm.formValidate.description,
      callback: function ($$v) {
        _vm.$set(_vm.formValidate, "description", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formValidate.description"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };