export default {
  name: 'AssocInfo',
  props: {
    assocVisible: {
      type: Boolean,
      default: false
    },
    assocErrorData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCloseFn() {
      this.$emit('update:assocVisible', false);
    }
  }
};