export default {
  name: 'ErrorInfo',
  props: {
    errorVisible: {
      type: Boolean,
      default: false
    },
    errorMsgData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCloseFn() {
      this.$emit('update:errorVisible', false);
    }
  }
};