var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table-column", {
    attrs: {
      prop: _vm.col[_vm.propKey],
      label: _vm.col[_vm.labelKey],
      align: "center",
      "min-width": _vm.handleFitWidthFn(_vm.col[_vm.labelKey], _vm.indexing),
      fixed: _vm.col[_vm.labelKey] === _vm.nameKey || _vm.col[_vm.labelKey] === _vm.mobileKey || _vm.col[_vm.labelKey] === _vm.paidWagesKey,
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("span", [_vm._v(_vm._s(_vm.handleDealFn(scope.row, _vm.col)))])];
      }
    }])
  }, [_vm.col[_vm.childrenKey] ? _vm._l(_vm.col[_vm.childrenKey], function (item, index) {
    return _c("column-item", {
      key: index,
      attrs: {
        col: item
      }
    });
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };