import { mapGetters } from 'vuex';
import DynamicTable from '@/components/dynamicTable/DynamicTable';
import Pagination from '@/components/pagination/Pagination';
import { dealTableHeaderData, dealTableData } from '@/views/payslip/util/compileData';
import { salaryDetailGetSalaryDetailPageList } from '@/api/salary';
export default {
  name: 'CheckPayslip',
  components: {
    DynamicTable,
    Pagination
  },
  data() {
    return {
      tableData: [],
      // 表格数据
      tableConfig: [],
      // 表头数据
      excelData: [],
      pages: {
        currentPage: 1,
        // 当前页码
        pageTotal: 0,
        // 数据总条目
        pageSize: 50 // 每页显示多少条数据
      }
    };
  },
  computed: {
    ...mapGetters({
      uploadRecordId: 'payslip/uploadRecordId'
    })
  },
  mounted() {
    this.fetchDataFn(this.pages);
  },
  methods: {
    // 请求工资表格分页数据
    async fetchDataFn(pages) {
      const {
        currentPage: pageNo,
        pageSize
      } = pages;
      const params = {
        uploadRecordId: this.uploadRecordId,
        needDecryptDetails: true,
        pageNo,
        pageSize
      };
      const res = await salaryDetailGetSalaryDetailPageList(params);
      if (res !== null && res !== void 0 && res.data) {
        const {
          items = [],
          total = '0'
        } = res.data;
        this.excelData = items;
        this.pages.pageTotal = Number(total);
        if (this.excelData.length > 0) {
          this.handleFormatDataFn();
        }
      }
    },
    handleFormatDataFn() {
      const {
        excelData
      } = this;
      this.tableConfig = dealTableHeaderData(excelData);
      this.tableData = dealTableData(excelData);
    },
    // 分页改变
    handleChangePageFn(val) {
      this.$refs.dynamicTableRef.$refs.tableRef.bodyWrapper.scrollTop = 0;
      this.pages = {
        ...this.pages,
        ...val
      };
      this.fetchDataFn(this.pages);
    }
  }
};