import { fitWidthFn, nameKey, mobileKey, paidWagesKey } from '@/views/payslip/util/compileData';
export default {
  name: 'ColumnItem',
  props: {
    col: {
      type: Object,
      required: true
    },
    indexing: {
      type: Number,
      default: 0
    },
    propKey: {
      type: String,
      default: 'value'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    childrenKey: {
      type: String,
      default: 'child'
    }
  },
  data() {
    return {
      nameKey,
      mobileKey,
      paidWagesKey
    };
  },
  methods: {
    handleFitWidthFn(key, index) {
      return fitWidthFn(key, index);
    },
    handleDealFn(row, col) {
      const {
        indexs
      } = col;
      const cellObj = row.find(item => {
        return item.indexs === indexs;
      });
      return (cellObj === null || cellObj === void 0 ? void 0 : cellObj.value) || '-';
    }
  }
};