var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-btn"
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "import-upload",
    attrs: {
      drag: "",
      action: "string",
      accept: ".xls,.xlsx",
      limit: 1,
      "show-file-list": false,
      "http-request": _vm.handleExcelUpload,
      "before-upload": _vm.beforeExcelUpload,
      "on-change": _vm.handleChangeFileFn,
      "on-error": _vm.handleError
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("将文件拖到此处，或"), _c("em", [_vm._v("点击上传")])]), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v(" 点击图标选择需要上传的Excel文件 ")])]), _vm.showProcess ? _c("el-progress", {
    staticClass: "upload-progress",
    attrs: {
      percentage: _vm.imgUploadPercent
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };