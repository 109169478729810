var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    ref: "tableRef",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      height: "98%"
    }
  }, [_c("el-table-column", {
    attrs: {
      fixed: "",
      type: "index",
      width: "50",
      label: "序号",
      align: "center",
      index: _vm.indexMethod
    }
  }), _vm._l(_vm.tableHeader, function (item, index) {
    return _c("column-item", {
      key: index,
      attrs: {
        col: item,
        indexing: index
      }
    });
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };