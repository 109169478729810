import "core-js/modules/es.array.push.js";
import Storage from '@/utils/storage';
import { mapGetters, mapMutations } from 'vuex';
import UploadBtn from '@/components/payslipUpload/UploadBtn';
import CheckPayslip from '@/components/payslipUpload/CheckPayslip';
import SendPayslip from '@/components/payslipUpload/SendPayslip';
import ErrorInfo from '@/components/payslipUpload/ErrorInfo';
import AssocInfo from '@/components/payslipUpload/AssocInfo';
import { formatChinaTime, fileDownload } from '@/utils/util';
import { uploadRecordGetUploadRecordErrorLog, salaryDetailUpdateSalaryDetailUserId } from '@/api/salary';
export default {
  name: 'PayslipUpload',
  components: {
    UploadBtn,
    CheckPayslip,
    SendPayslip,
    ErrorInfo,
    AssocInfo
  },
  data() {
    return {
      active: 1,
      stepData: [{
        index: 0,
        title: '上传工资表'
      }, {
        index: 1,
        title: '核对工资表'
      }, {
        index: 2,
        title: '发送工资条'
      }],
      templateData: ['姓名', '手机号', '实发工资'],
      yearMonth: formatChinaTime(new Date(), 'yyyyMM'),
      pickerOptions: {
        disabledDate(time) {
          // 设置选择本月以及之前的日期
          return time.getTime() > Date.now();
        }
      },
      errorVisible: false,
      // 错误信息弹窗是否显示
      excelData: [],
      errorMsgData: [],
      // 失败数据
      repeatVisible: false,
      // 重复信息弹窗是否显示
      repeatPhonesData: [],
      // 重复手机号数据
      hasCheck: false,
      // 是否核对关联员工
      assocVisible: false,
      // 核对关联员工弹窗是否显示
      assocErrorData: [] // 关联失败数据
    };
  },
  computed: {
    ...mapGetters({
      uploadRecordId: 'payslip/uploadRecordId',
      uploadStep: 'payslip/uploadStep'
    })
  },
  watch: {
    active(val) {
      if (val === 2) {
        this.fetchErrorDataFn();
      }
    }
  },
  created() {
    const {
      manager
    } = this.$route.query;
    if (typeof manager === 'string') {
      this.yearMonth = Storage.sessionGet('yearMonth');
      this.active = Number(this.uploadStep);
    }
  },
  beforeDestroy() {
    window.onresize = null;
  },
  methods: {
    ...mapMutations('payslip', ['setUploadRecordId']),
    // 步骤条下一步的方法
    handleNext() {
      if (this.active++ > 2) {
        this.active = 1;
      }
    },
    // 步骤条上一步的方法
    handlePrev() {
      if (this.active-- < 2) {
        this.active = 1;
      }
    },
    // 上传成功的回调方法
    onSuccess(uploadRecordId) {
      this.setUploadRecordId(uploadRecordId);
      this.handleNext();
    },
    // 下载模板
    onDownloadTemplate() {
      fileDownload('./static/工资表模板.xlsx', '工资表模板.xlsx');
    },
    // 返回首页
    goManagerPageFn() {
      this.$router.push({
        path: '/payslip-manager'
      });
    },
    // 核对关联员工
    async checkEmployeesFn() {
      const res = await salaryDetailUpdateSalaryDetailUserId({
        uploadRecordId: this.uploadRecordId
      });
      if (res !== null && res !== void 0 && res.data) {
        this.hasCheck = true;
        const mobileList = res.data;
        this.assocErrorData = mobileList || [];
        if (this.assocErrorData.length === 0) {
          this.$message.success('核对成功!');
        }
      }
    },
    // 更新工资详情表中的userId
    async handleNextStep() {
      this.handleNext();
    },
    // 获取错误数据
    async fetchErrorDataFn() {
      const res = await uploadRecordGetUploadRecordErrorLog({
        id: this.uploadRecordId
      });
      const {
        parseErrorLogs,
        repeatPhones
      } = res.data;
      this.errorMsgData = parseErrorLogs || [];
      this.repeatPhonesData = repeatPhones || [];
    },
    showErrorDialogFn() {
      this.errorVisible = true;
    },
    showRepeatDialogFn() {
      this.repeatVisible = true;
    },
    showAssocErrorDialogFn() {
      this.assocVisible = true;
    },
    handleSendFn() {
      this.$refs.sendChildRef.handleSubmitFn();
    }
  }
};