var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "wrapper",
    staticClass: "check-payslip"
  }, [_vm.tableData.length > 0 ? _c("div", {
    staticClass: "check-payslip-table"
  }, [_c("DynamicTable", {
    ref: "dynamicTableRef",
    attrs: {
      "table-data": _vm.tableData,
      "table-header": _vm.tableConfig,
      pages: _vm.pages
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "check-payslip-pagination"
  }, [_c("Pagination", {
    attrs: {
      "page-no": _vm.pages.currentPage,
      limit: _vm.pages.pageSize,
      "page-total": _vm.pages.pageTotal
    },
    on: {
      "update:pageNo": function ($event) {
        return _vm.$set(_vm.pages, "currentPage", $event);
      },
      "update:page-no": function ($event) {
        return _vm.$set(_vm.pages, "currentPage", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.pages, "pageSize", $event);
      },
      changePagination: _vm.handleChangePageFn
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };