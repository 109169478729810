import { uploadRecordUploadSalaryExcel } from '@/api/salary';
export default {
  name: 'UploadBtn',
  props: {
    yearMonth: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      msg: null,
      interval: null,
      // 进度条定时器
      showProcess: false,
      // 是否显示进度条
      imgUploadPercent: 0 // 进度条的进度
    };
  },
  methods: {
    // 文件状态改变时，处理进度条的进度
    handleChangeFileFn(file) {
      if (file.status === 'ready') {
        this.imgUploadPercent = 0;
        this.showProcess = true;
        this.interval = setInterval(() => {
          if (this.imgUploadPercent >= 99) {
            clearInterval(this.interval);
            return;
          }
          this.imgUploadPercent += 1;
        }, 20);
      }
      if (file.status === 'success') {
        this.imgUploadPercent = 100;
        this.showProcess = false;
      }
    },
    // excel文件上传前校验
    beforeExcelUpload(file) {
      this.msg && this.msg.close();
      const excelType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
      const isExcel = excelType.includes(file.type); // 是否是excel文件
      const isLt5MExcel = file.size / 1024 / 1024 < 5; // 是否小于5M的excel文件
      if (!isExcel) {
        this.msg = this.$message({
          message: '请上传Excel文件!',
          type: 'error'
        });
      }
      if (!isLt5MExcel) {
        this.msg = this.$message({
          message: '上传Excel大小不能超过 5MB!',
          type: 'error'
        });
      }
      if (!isExcel || !isLt5MExcel) {
        this.handleResetProgressFn();
      }
      return isExcel && isLt5MExcel;
    },
    // 上传excel文件
    async handleExcelUpload(param) {
      this.msg && this.msg.close();
      this.$refs.upload.clearFiles(); // 清空已上传的文件列表

      const {
        file
      } = param;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('salaryYearMonth', this.yearMonth);
      const env = process.env.NODE_ENV;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          PublicKey: window.global_config[env]
        },
        // 关闭loading提示
        hideLoading: true
      };
      try {
        const res = await uploadRecordUploadSalaryExcel(formData, config);
        if (res !== null && res !== void 0 && res.data) {
          const uploadRecordId = res.data;
          this.$emit('onSuccess', uploadRecordId);
        } else {
          this.handleResetProgressFn();
        }
      } catch (err) {
        console.error(err);
        this.handleError(err);
      }
    },
    // 上传失败
    handleError(err) {
      console.error('err', err);
      this.handleResetProgressFn();
      this.msg = this.$message({
        message: '上传失败',
        type: 'error'
      });
    },
    handleResetProgressFn() {
      this.interval && clearInterval(this.interval);
      this.imgUploadPercent = 0;
      this.showProcess = false; // 不显示进度条
    }
  }
};