var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      title: "提示",
      visible: _vm.errorVisible,
      width: "400px",
      center: "",
      "before-close": _vm.handleCloseFn,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.errorVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "header-title"
  }, [_vm._t("custom-title")], 2), _c("div", {
    staticClass: "error-con"
  }, _vm._l(_vm.errorMsgData, function (item) {
    return _c("span", {
      key: item
    }, [_vm._v(_vm._s(item))]);
  }), 0), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCloseFn
    }
  }, [_vm._v("确 定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };