import { mapGetters } from 'vuex';
import { getYearMonth } from '@/views/payslip/util/compileData';
import { userSendMessage } from '@/api/salary';
import { objToQueryString } from '@/utils/util';
export default {
  name: 'SendPayslip',
  props: {
    yearMonth: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      formValidate: {
        title: '',
        description: '',
        url: '',
        state: '',
        // 重定向后会带上state参数
        uploadRecordId: '',
        year: '',
        month: ''
      },
      ruleValidate: {
        title: [{
          required: true,
          message: '请输入工资标题',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      uploadRecordId: 'payslip/uploadRecordId'
    })
  },
  watch: {
    yearMonth: {
      handler(val) {
        if (val) {
          this.handleParamDataFn(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    getOrigin() {
      return window.location.origin;
    },
    // 处理数据
    handleParamDataFn(data) {
      const {
        year,
        month
      } = getYearMonth(data);
      const path = '/payslip-detail';
      // 因为微信会自动解码，故传参时要转码
      const stateObj = {
        path: encodeURIComponent(path),
        year,
        month,
        uploadRecordId: this.uploadRecordId
      };
      this.formValidate.year = year;
      this.formValidate.month = month;
      this.formValidate.uploadRecordId = this.uploadRecordId;
      this.formValidate.title = `${year}年${month}月工资条`;
      this.formValidate.url = this.getOrigin();
      const state = objToQueryString(stateObj);
      this.formValidate.state = encodeURIComponent(state);
    },
    handleSubmitFn(name = 'formValidate') {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.sendMessageFn(this.formValidate);
        }
      });
    },
    // 推送工资消息
    async sendMessageFn(params) {
      const res = await userSendMessage(params);
      if (res !== null && res !== void 0 && res.data) {
        const msg = this.$message({
          message: '操作成功',
          type: 'success'
        });
        setTimeout(() => {
          msg.close();
          this.goManagerPageFn();
        }, 500);
      }
    },
    goManagerPageFn() {
      this.$router.replace({
        path: '/payslip-manager'
      });
    }
  }
};